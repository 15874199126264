<div class="flex-column p-2">
    <label
        class="form-label"
        for="spokenLanguage"
        translate="inclusion.contact.language.title"
    ></label>
    <select
        id="spokenLanguage"
        class="form-control form-select input-width"
        [(ngModel)]="spokenLanguage"
        (ngModelChange)="onChange($event)"
    >
        <option
            *ngFor="let language of languages"
            [value]="language"
            [translate]="'inclusion.contact.language.' + language"
        ></option>
    </select>
</div>
