import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SpokenLanguagesService } from './services/spoken-language.service';

@UntilDestroy()
@Component({
    selector: 'app-spoken-language-selector',
    templateUrl: './spoken-language-selector.component.html',
    styleUrls: ['./spoken-language-selector.component.scss'],
})
export class SpokenLanguageSelectorComponent implements OnInit {
    @Input()
    public spokenLanguage: string;

    @Output()
    private handleSpokenLanguageChange = new EventEmitter<string>();

    public languages = this.spokenLanguagesService.allLanguages;

    constructor(
        private readonly spokenLanguagesService: SpokenLanguagesService,
    ) {}

    ngOnInit() {
        this.spokenLanguage =
            this.spokenLanguage === undefined
                ? this.spokenLanguagesService.defaultLanguage
                : this.spokenLanguage;
    }

    onChange(spokenLanguage) {
        this.handleSpokenLanguageChange.emit(spokenLanguage);
    }
}
