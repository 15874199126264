import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { isNumber, some } from 'lodash';
import { ImmunosuppressantService } from '@services/form/immunosuppressant.service';
import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class FormPatientNephroService {
    immunosuppressants;
    careStaffs;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private immunosuppressantService: ImmunosuppressantService,
    ) {}

    loadCarestaff() {
        if (!this.authService.isAdmin) {
            return of(true);
        }
        return forkJoin([
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/transplant_center`,
            ),
            this.http.get<any>(`${environment.apiUrl}/users/simple/doctor`),
            this.http.get<any>(`${environment.apiUrl}/users/simple/ide`),
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/therapeutic_coach`,
            ),
            this.http.get<any>(`${environment.apiUrl}/users/simple/installer`),
        ]).pipe(
            map(
                ([
                    transplantCenter,
                    doctor,
                    ide,
                    therapeuticCoach,
                    installer,
                ]) =>
                    (this.careStaffs = {
                        transplantCenter: transplantCenter.users,
                        doctor: doctor.users,
                        ide: ide.users,
                        therapeuticCoach: therapeuticCoach.users,
                        installer: installer.users,
                    }),
            ),
        );
    }

    loadImmunosuppressants() {
        return this.immunosuppressantService.getImmunosuppressants().pipe(
            tap((immunosuppressants) => {
                this.immunosuppressants = immunosuppressants;
            }),
        );
    }

    checkInclusionModePatient(patient) {
        return patient.specialistId;
    }

    checkIdentityPatient(patient) {
        return (
            patient.sex &&
            patient.lastName &&
            patient.firstName &&
            patient.birthDate &&
            patient.socialSecurityNumber
        );
    }

    checkContactPatient(patient) {
        return (
            (patient.phones.first || patient.phones.second) &&
            patient.address?.completeAddress
        );
    }

    checkMedicalDataPatient(patient) {
        return (
            patient.medicalData.transplantDate &&
            patient.medicalData.size &&
            some(patient.medicalData.significantEvent, (event) => !!event)
        );
    }

    checkMedicalData2Patient(patient) {
        if (patient.medicalData.heartRate === 'other') {
            return (
                patient.medicalData.heartRateCustom1 &&
                patient.medicalData.heartRateCustom2
            );
        }

        return patient.medicalData.heartRate;
    }

    checkImmunosuppressantsPatient(patient) {
        const listTreatment = this.immunosuppressants?.map(({ key }) => key);

        return patient.treatmentImmunosuppressant?.some(
            ({ name, value, frequency, borne }) =>
                listTreatment.includes(name) &&
                isNumber(value) &&
                isNumber(frequency) &&
                isNumber(borne.min) &&
                isNumber(borne.max),
        );
    }

    checkOtherTreatmentsPatient() {
        return true;
    }

    checkCarestaffPatient(patient) {
        return !!patient.specialistId;
    }

    checkAlgorithmDataPatient(patient) {
        return this.checkImmunosuppressantsPatient(patient);
    }

    checkMedicalDataReinclusionPatient(patient) {
        return some(patient.medicalData.significantEvent, (event) => !!event);
    }

    checkSettingsPatient(patient) {
        return patient.alertInterval;
    }
}
