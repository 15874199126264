import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BackPageService {
    private readonly BACK_PAGE_COOKIE = 'back-pages';
    private readonly MAX_HISTORY_ENTRIES = 10;

    constructor(private cookieService: CookieService, private router: Router) {}

    public saveBackPage(url: string, params?: any): void {
        const backPages =
            (this.cookieService.getObject(this.BACK_PAGE_COOKIE) as any[]) ||
            [];

        backPages.push({ url, params });

        if (backPages.length > this.MAX_HISTORY_ENTRIES) {
            backPages.shift();
        }

        const expires = new Date();
        expires.setTime(expires.getTime() + 8 * 60 * 60 * 1000);

        this.cookieService.putObject(this.BACK_PAGE_COOKIE, backPages, {
            domain: environment.cookieDomain,
            sameSite: 'lax',
            expires,
        });
    }

    public goBackPage(): void {
        const backPages =
            (this.cookieService.getObject(this.BACK_PAGE_COOKIE) as any[]) ||
            [];

        if (!backPages.length) {
            this.router.navigateByUrl('patients');
            return;
        }

        const lastPage = backPages.pop();

        const expires = new Date();
        expires.setTime(expires.getTime() + 8 * 60 * 60 * 1000);

        this.cookieService.putObject(this.BACK_PAGE_COOKIE, backPages, {
            domain: environment.cookieDomain,
            sameSite: 'lax',
            expires,
        });

        if (!lastPage.params) {
            this.router.navigate([lastPage.url]);
        } else {
            this.router.navigate([lastPage.url], {
                queryParams: { ...lastPage.params },
            });
        }
    }

    public getParamsRoute(): any {
        const backPages =
            (this.cookieService.getObject(this.BACK_PAGE_COOKIE) as any[]) ||
            [];
        if (!backPages.length) {
            return null;
        }

        return backPages[backPages.length - 1].params;
    }
}
