import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { InsiConsultData } from '@app/insi-consult/interfaces/insi-consult.interface';
import { ChangeDeathDateRequest } from '@app/patient/interfaces/change-death-date.interface';

@Injectable({ providedIn: 'root' })
export class PatientService {
    onMeasureAdded$ = new Subject();

    constructor(private http: HttpClient) {}

    getPatient(id, fields, disease?, reinclusion?, companyId?) {
        const params: any = {
            fields,
        };

        const headers: any = new HttpHeaders({
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
        });

        if (disease) {
            params.disease = disease;
        }

        if (reinclusion) {
            params.reinclusion = true;
        }

        if (companyId) {
            params.companyId = companyId;
        }

        return this.http
            .get<any>(`${environment.apiUrl}/patient/${id}`, {
                params,
                headers,
            })
            .pipe(map(({ patient }) => patient));
    }

    hideNotificationToComplete(id) {
        return this.http
            .put<any>(`${environment.apiUrl}/patient/${id}/notification`, {
                hideToComplete: true,
            })
            .pipe(map(({ patient }) => patient));
    }

    addComment(id, category, comments, disease) {
        const params = { disease };
        return this.http.post<any>(
            `${environment.apiUrl}/action/add-comment/patient/${id}`,
            { category, comments },
            { params },
        );
    }

    checkPrescriptionTemplate(disease) {
        return (
            disease?.prescriptionTemplateAuthorization &&
            disease.prescriptionTemplate?.authorizationFile &&
            disease.prescriptionTemplate.templateFile
        );
    }

    changeMedicalStatus(id, body, disease) {
        const params = new HttpParams().append('disease', disease);
        const url = `${environment.apiUrl}/action/change-medical-status/patient/${id}`;
        return this.http.post<any>(url, body, { params });
    }

    triggerAlertTechnical(id, body, disease) {
        const params = new HttpParams().append('disease', disease);
        const url = `${environment.apiUrl}/action/trigger-alert0/patient/${id}`;
        return this.http.post<any>(url, body, { params });
    }

    editBiologicData(id, body, disease) {
        const url = `${environment.apiUrl}/patient/${id}/biologicData`;
        const params = new HttpParams().set('disease', disease);
        return this.http.put<any>(url, body, { params });
    }

    addBiologicData(id, body, disease) {
        const url = `${environment.apiUrl}/patient/${id}/biologicData`;
        const params = new HttpParams().set('disease', disease);
        return this.http.post<any>(url, body, { params });
    }

    updateViewedBySpecialist(id, disease) {
        const url = `${environment.apiUrl}/patient/${id}/viewedBySpecialist`;
        const params = new HttpParams().set('disease', disease);
        return this.http.put<any>(url, null, { params });
    }

    lockPrescription(id, inclusionId, disease, body = null) {
        const url = `${environment.apiUrl}/patient/${id}/lock-prescription/${inclusionId}`;
        const params = new HttpParams().set('disease', disease);
        return this.http.put<any>(url, body, { params });
    }

    saveHistoryInclusion(id, body, disease) {
        const url = `${environment.apiUrl}/patient/${id}/history-inclusion`;
        const params = new HttpParams().set('disease', disease);
        return this.http.put<any>(url, body, { params });
    }

    cancelInclusion(inclusionId, patientId, body) {
        const url = `${environment.apiUrl}/patient/${patientId}/${inclusionId}/cancel-inclusion`;
        return this.http.put<any>(url, body);
    }

    addPatientDemo() {
        return this.http.post<any>(`${environment.apiUrl}/patient`, {
            demo: true,
        });
    }

    searchPatient(firstName: string, socialSecurityNumber: string) {
        return this.http.post<
            | {
                  firstName: string;
                  lastName: string;
                  _id: string;
                  createdAt: any;
              }
            | undefined
        >(`${environment.apiUrl}/patient/is-present`, {
            firstName,
            nir: socialSecurityNumber,
        });
    }

    qualifyINS(insiConsultData: InsiConsultData, patientId: string) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/ins`,
            {
                data: insiConsultData,
            },
        );
    }

    logINSActivity(activityType, activityContent, patientId: string) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/ins/activity`,
            {
                activityContent,
                activityType,
            },
        );
    }

    changeDeathDate(request: ChangeDeathDateRequest, demo = false) {
        if (demo) {
            return of([true]);
        }

        return this.http.post<any>(
            `${environment.apiUrl}/change-death-date/${request.patient}`,
            {
                disease: request.disease,
                date: request.date,
            },
        );
    }
}
