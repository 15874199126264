import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SpokenLanguagesService {
    private readonly french = 'fr';

    private readonly languages = [this.french, 'en', 'nl'];

    get allLanguages() {
        return this.languages;
    }

    get defaultLanguage() {
        return this.french;
    }
}
